import React from 'react';

const Footer = () => {
    return (
        <footer className="footer footer-center p-10 bg-base-200 text-base-content">
            <div>
                <div className="grid grid-flow-col gap-4">
                    <a href='https://www.facebook.com/stemit.bd' target='_blank' className='mx-2 text-primary hover:text-[#4DD637]'><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" className="fill-current"><path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"></path></svg></a>
                    <a href='https://www.linkedin.com/in/shafayat-chowdhury-39a57a1a7' target='_blank' className='mx-2 text-primary hover:text-[#4DD637]'><svg xmlns="http://www.w3.org/2000/svg" height='30' width='30' viewBox="0 0 448 512" className="fill-current"><path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" /></svg></a>
                    <a href="https://www.youtube.com/channel/UCoB2GvWXXq_6guoM1FeuGfw" target='_blank' className='mx-2 text-primary hover:text-[#4DD637]'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="30" height="30" className="fill-current"><path d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"/></svg></a>
                </div>
            </div>
            <div>
                <p>Copyright © 2022 - All right reserved by STEM IT</p>
                <div className='mt-4'>
                    <p>Designed by <a className='link-primary' href='https://www.linkedin.com/in/tohidul-islam-5019b9223/' target='_blank'>MD TOHIDUL ISLAM</a></p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;