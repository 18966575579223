import React from 'react';

const Course = ({ course }) => {
    return (
        <div className="card w-84 bg-base-100 shadow-xl">
            <figure className="px-10 pt-10">
                <img src={course.img} alt="course" className="rounded-2xl" />
            </figure>
            <div className="card-body items-center text-center">
                <div className='my-4'>
                    <h2 className="card-title text-2xl text-primary">{course.name}</h2>
                </div>
                <div className="my-auto">
                    <p>{course.course_details}</p>
                </div>
                <div className='my-4'>
                    <h3 className="card-title">কোর্স সময়সীমা: {course.duration} মাস</h3>
                    <h3 className="card-title mt-2">কোর্স ফি: ৳{course.course_fee} মাত্র</h3>
                </div>
            </div>
        </div>
    );
};

export default Course;