import { faKeyboard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';

const WPM = () => {
    return (
        <div>
            <div className='text-center mt-8'>
                <h1 className='text-primary lg:text-3xl text-xl mt-12'>Welcome to Typing Test <FontAwesomeIcon icon={faKeyboard} /></h1>
            </div>
            <div className='text-center mt-8'>
                <Link to="/WPM/English" className="no-underline hover:underline link link-accent m-10 text-2xl lg:inline-block block">English Typing</Link>
                <Link to="/WPM/Bangla" className="no-underline hover:underline link text-[#EDC126] m-10 text-2xl hover:text-[#A77B06]">Bangla Typing</Link>
            </div>
        </div>
    );
};

export default WPM;