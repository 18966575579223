import React, { useEffect, useState } from 'react';
import OfficeStudent04 from './OfficeStudent04';

const OfficeApplication04 = () => {
    const [officeStudents, setOfficeStudents] = useState([]);
    useEffect(() => {
        fetch('office-application-04.json')
            .then(res => res.json())
            .then(data => setOfficeStudents(data))
    })
    return (
        <div>
            <h2 className='lg:text-4xl text-2xl text-center my-10 mt-24 text-[#3DBE29]'>BATCH-04</h2>
            <div className='grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 lg:gap-10 gap-5 mx-10 my-10'>
                {
                    officeStudents.map(student =>
                        <OfficeStudent04
                            key={student.id}
                            student={student}
                        ></OfficeStudent04>)
                }
            </div>
        </div>
    );
};

export default OfficeApplication04;