import React from 'react';
import CourseNameNav from '../../Shared/CourseNameNav/CourseNameNav';

const AllEnglishSpokenStudents = () => {
    return (
        <div>
            <CourseNameNav></CourseNameNav>
        </div>
    );
};

export default AllEnglishSpokenStudents;