import { Route, Routes } from "react-router-dom";
import About from "./components/About/About";
import Admission from "./components/Admission/Admission";
import Contact from "./components/Contact/Contact";
import Home from "./components/Home/Home";
import NotFound from "./components/NotFound/NotFound";
import Navbar from "./components/Shared/Navbar/Navbar";
import AllEnglishSpokenStudents from "./components/Students/AllEnglishSpokenStudents/AllEnglishSpokenStudents";
import AllOfficeStudents from "./components/Students/OfficeApplication/AllOfficeStudents";
import Students from "./components/Students/Students";
import Bangla from "./components/WPM/Bangla/Bangla";
import English from "./components/WPM/English/English";
import WPM from "./components/WPM/WPM";
import Gallery from "./components/Gallery/Gallery";

function App() {
  return (
    <div>
      <Navbar></Navbar>
      <Routes>
        <Route path='/' element={<Home></Home>}></Route>
        <Route path='/home' element={<Home></Home>}></Route>
        <Route path='/about' element={<About></About>}></Route>
        <Route path='/contact' element={<Contact></Contact>}></Route>
        <Route path='/students' element={<Students></Students>}></Route>
        <Route path='/gallery' element={<Gallery></Gallery>}></Route>
        <Route path="/office-application-students" element={<AllOfficeStudents></AllOfficeStudents>}></Route>
        <Route path="/english-spoken-students" element={<AllEnglishSpokenStudents></AllEnglishSpokenStudents>}></Route>
        <Route path='/WPM' element={<WPM></WPM>}></Route>
        <Route path='/WPM/English' element={<English></English>}></Route>
        <Route path='/WPM/Bangla' element={<Bangla></Bangla>}></Route>
        <Route path='/admission' element={<Admission></Admission>}></Route>
        {/* <Route path='/stemacademy' element={<StemAcademy></StemAcademy>}></Route>
        <Route path="/stemacademy/officecourse" element={<OfficeCourse></OfficeCourse>}></Route>
        <Route path="/stemacademy/englishcourse" element={<EnglishCourse></EnglishCourse>}></Route> */}
        <Route path='*' element={<NotFound></NotFound>}></Route>
      </Routes>
    </div>
  );
}

export default App;
