import React from 'react';
import image from '../../images/NotFound/404.gif';

const NotFound = () => {
    return (
        <div>
            <img className='lg:mx-auto lg:mt-[20px] sm:h-full sm:w-full lg:h-[600px] lg:w-[900px]' src={image} alt="404 error message" />
        </div>
    );
};

export default NotFound;