import React, { Component } from "react";
import '../WPM.css'

class English extends Component {
    state = {
        text: "",
        inputValue: "",
        lastLetter: "",
        words: [],
        completedWords: [],
        completed: false,
        startTime: undefined,
        timeElapsed: 0,
        wpm: 0,
        started: false,
        progress: 0
    };

    setText = () => {
        const texts = [
            `You never read a book on psychology, Tippy. You didn't need to. You knew by some divine instinct that you can make more friends in two months by becoming genuinely interested in other people than you can in two years by trying to get other people interested in you.`,
            `I know more about the private lives of celebrities than I do about any governmental policy that will actually affect me. I'm interested in things that are none of my business, and I'm bored by things that are important to know.`,
            `As customers of all races, nationalities, and cultures visit the Dekalb Farmers Market by the thousands, I doubt that many stand in awe and contemplate the meaning of its existence. But in the capital of the Sunbelt South, the quiet revolution of immigration and food continues to upset and redefine the meanings of local, regional, and global identity.`,
            `Outside of two men on a train platform there's nothing in sight. They're waiting for spring to come, smoking down the track. The world could come to an end tonight, but that's alright. She could still be there sleeping when I get back.`
        ];
        const text = texts[Math.floor(Math.random() * texts.length)];
        const words = text.split(" ");

        this.setState({
            text: text,
            words: words,
            completedWords: []
        });
    };

    startGame = () => {
        this.setText();

        this.setState({
            started: true,
            startTime: Date.now(),
            completed: false,
            progress: 0
        });
    };

    handleChange = e => {
        const { words, completedWords } = this.state;
        const inputValue = e.target.value;
        const lastLetter = inputValue[inputValue.length - 1];

        const currentWord = words[0];
        console.log(currentWord, "currentWord");

        // if space or '.', check the word
        if (lastLetter === " " || lastLetter === ".") {
            // check to see if it matches to the currentWord
            // trim because it has the space
            if (inputValue.trim() === currentWord) {
                // remove the word from the wordsArray
                // cleanUp the input
                const newWords = [...words.slice(1)];
                console.log(newWords, "newWords");
                console.log(newWords.length, "newWords.length");
                const newCompletedWords = [...completedWords, currentWord];
                console.log(newCompletedWords, "newCompletedWords");
                console.log("----------------");

                // Get the total progress by checking how much words are left
                const progress =
                    (newCompletedWords.length /
                        (newWords.length + newCompletedWords.length)) *
                    100;
                this.setState({
                    words: newWords,
                    completedWords: newCompletedWords,
                    inputValue: "",
                    completed: newWords.length === 0,
                    progress: progress
                });
            }
        } else {
            this.setState({
                inputValue: inputValue,
                lastLetter: lastLetter
            });
            console.log(this.state.inputValue, "this.state.inputValue");
            console.log(this.state.lastLetter, "this.state.lastLetter");
            console.log("================================");
        }

        this.calculateWPM();
    };

    calculateWPM = () => {
        const { startTime, completedWords } = this.state;
        const now = Date.now();
        const diff = (now - startTime) / 1000 / 60; // 1000 ms / 60 s
        console.log(now, "now");
        console.log(startTime, "startTime");
        console.log(diff, "diff");
        console.log("**************");

        // every word is considered to have 5 letters
        // so here we are getting all the letters in the words and divide them by 5
        // "my" shouldn't be counted as same as "deinstitutionalization"
        const wordsTyped = Math.ceil(
            completedWords.reduce((acc, word) => (acc += word.length), 0) / 5
        );
        console.log(completedWords, "completedWords");
        console.log(wordsTyped, "wordsTyped");
        console.log("+=+=+=+=+=+=");

        // calculating the wpm
        const wpm = Math.ceil(wordsTyped / diff);

        this.setState({
            wpm: wpm,
            timeElapsed: diff
        });
    };

    render() {
        const {
            text,
            inputValue,
            completedWords,
            wpm,
            timeElapsed,
            started,
            completed,
            progress
        } = this.state;

        if (!started)
            return (
                <div className="container">
                    <h2 className="text-xl">Welcome to the Typing Test</h2>
                    <p className="text-center mt-4 text-lg">
                        <strong>Rules:</strong> <br />
                        Type in the input field the highlighted word. <br />
                        The correct words will turn <span className="text-green-500">green</span>.
                        <br />
                        Incorrect letters will turn <span className="red">red</span>.
                        <br />
                        <br />
                    </p>
                    <button className="btn btn-xl w-44 mx-auto btn-primary text-white text-xl" onClick={this.startGame}>
                        Start Test
                    </button>
                </div>
            );

        if (!text) return <p>Loading...</p>;

        if (completed) {
            return (
                <div className="container">
                    <h2 className="text-xl">
                        Your WPM is <strong>{wpm}</strong> in English Typing
                    </h2>
                    <button className="btn btn-xl w-44 mx-auto btn-primary text-white text-xl" onClick={this.startGame}>
                        Test Again
                    </button>
                </div>
            );
        }

        return (
            <div>
                <div className="wpm">
                    <strong>WPM: </strong>
                    {wpm}
                    <br />
                    <strong>Time: </strong>
                    {Math.floor(timeElapsed * 60)}s
                </div>
                <div className="container w-full px-4">
                    <h4>Type the text below</h4>
                    <progress value={progress} max="100" />
                    <p className="text text-2xl">
                        {text.split(" ").map((word, w_idx) => {
                            let highlight = false;
                            let currentWord = false;

                            // this means that the word is completed, so turn it green
                            if (completedWords.length > w_idx) {
                                highlight = true;
                            }

                            if (completedWords.length === w_idx) {
                                currentWord = true;
                            }

                            return (
                                <span
                                    className={`word 
                                ${highlight && "text-green-500"} 
                                ${currentWord && "underline"}`}
                                    key={w_idx}
                                >
                                    {word.split("").map((letter, l_idx) => {
                                        const isCurrentWord = w_idx === completedWords.length;
                                        const isWronglyTyped = letter !== inputValue[l_idx];
                                        const shouldBeHighlighted = l_idx < inputValue.length;

                                        return (
                                            <span
                                                className={`letter ${isCurrentWord && shouldBeHighlighted
                                                    ? isWronglyTyped
                                                        ? "red"
                                                        : "green"
                                                    : ""
                                                    }`}
                                                key={l_idx}
                                            >
                                                {letter}
                                            </span>
                                        );
                                    })}
                                </span>
                            );
                        })}
                    </p>
                    <input
                        type="text"
                        onChange={this.handleChange}
                        value={inputValue}
                        // autoFocus={started ? 'true' : 'false'}
                        autoFocus={true}
                    />
                </div>
            </div>
        );
    }
}

export default English;
