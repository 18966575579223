import React, { useEffect, useState } from 'react';
import OfficeStudent02 from './OfficeStudent02';

const OfficeApplication02 = () => {
    const [officeStudents, setOfficeStudents] = useState([]);
    useEffect(() => {
        fetch('office-application-02.json')
            .then(res => res.json())
            .then(data => setOfficeStudents(data))
    })
    return (
        <div>
            <h2 className='lg:text-4xl text-2xl text-center my-10 text-[#3DBE29]'>BATCH-02</h2>
            <div className='grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 lg:gap-10 gap-5 mx-10 my-10'>
                {
                    officeStudents.map(student =>
                        <OfficeStudent02
                            key={student.id}
                            student={student}
                        ></OfficeStudent02>)
                }
            </div>
        </div>
    );
};

export default OfficeApplication02;