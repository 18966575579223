import React from 'react';
import whyImg from '../../../images/images/why.jpg';

const WhyStemIT = () => {
    return (
        <div className='lg:my-24'>
            <div className="card lg:card-side bg-base-100 shadow-xl mt-12 lg:mx-24 my-12 mx-10 flex-col-reverse">
                <div className="card-body lg:w-1/2">
                    <h2 className="card-title">কেন স্ট্যাম আইটিকে বাছাই করে নিবেন?</h2>
                    <p>স্ট্যাম আইটি দীর্ঘ ৪ বছর যাবত অত্যন্ত সুনামের সাথে বেসিক কম্পিউটার, গ্রাফিক ডিজাইন, ওয়েব ডেভেলপমেন্ট প্রশিক্ষণ দিয়ে আসছে এবং ইতিমধ্যে প্রায় ৩০০ শিক্ষার্থী এখানে প্রশিক্ষণ নিয়েছে। প্রশিক্ষণ শেষে সরকারি সার্টিফিকেট পাওয়ার কারণে শিক্ষার্থীদের চাকরি পাওয়ার সম্ভাবনা বহুলাংশে বেড়েছে।
                        স্ট্যাম আইটিতে অভিজ্ঞ ইন্সট্রাকটর দ্বারা যত্নসহকারে  বিভিন্ন কোর্সের প্রশিক্ষণের ব্যবস্থা করা হয়। এখানে রয়েছে সার্বক্ষণিক সিসি ক্যামেরার পর্যবেক্ষণ যা শিক্ষার্থীদের নিরাপত্তা শতভাগ নিশ্চিত করে। শহরের প্রাণকেন্দ্রে অবস্থান হলেও স্ট্যাম আইটির পরিবেশ অত্যন্ত মনোরম এবং ক্যাম্পাস যথেষ্ট বড়।
                        এছাড়া স্ট্যাম আইটির পরিবেশ একদমই কোলাহলপূর্ণ না যা শিক্ষার্থীদের মনোযোগ সহকারে ক্লাস করতে অন্যতম ভূমিকা পালন করে।</p>
                    <p>স্ট্যাম আইটি প্রতিষ্ঠার মূল উদ্দেশ্য যথাযথ প্রশিক্ষণ প্রদানের মাধ্যমে বেকার চাকরিপ্রত্যাশীদের দক্ষ করে গড়ে তোলা এবং বর্তমানে শিক্ষারত ছাত্র-ছাত্রীদের কম্পিউটারে দক্ষ হিসেবে গড়ে তোলা যেন ভবিষ্যতে চাকরির ক্ষেত্রে কম্পিউটার-জ্ঞান উপযুক্ত কাজে লাগানো যায়।</p>
                </div>
                <div className='lg:w-1/2 my-auto'>
                    <figure><img src={whyImg} alt="Album" className='rounded-xl' /></figure>
                </div>
            </div>
        </div>
    );
};

export default WhyStemIT;