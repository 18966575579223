import React from 'react';
import CourseNameNav from '../../Shared/CourseNameNav/CourseNameNav';
import OfficeApplication01 from './OfficeApplication01';
import OfficeApplication02 from './OfficeApplication02';
import OfficeApplication03 from './OfficeApplication03';
import OfficeApplication04 from './OfficeApplication04';
import OfficeApplication05 from './OfficeApplication05';

const AllOfficeStudents = () => {
    return (
        <div>
            <CourseNameNav></CourseNameNav>
            <OfficeApplication01></OfficeApplication01>
            <OfficeApplication02></OfficeApplication02>
            <OfficeApplication03></OfficeApplication03>
            <OfficeApplication04></OfficeApplication04>
            <OfficeApplication05></OfficeApplication05>
        </div>
    );
};

export default AllOfficeStudents;