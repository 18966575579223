import React from 'react';
import mainImg from '../../../images/images/stemit.jpg'

const WhatIsStemIT = () => {
    return (
        <div className="card lg:card-side bg-base-100 shadow-xl mt-12 lg:mx-24 my-12 mx-10">
            <div className='lg:w-1/2'>
                <figure><img src={mainImg} alt="Album" className='rounded-xl' /></figure>
            </div>
            <div className="card-body lg:w-1/2">
                <h2 className="card-title">স্ট্যাম আইটি (STEM IT)</h2>
                <p>স্ট্যাম আইটি বাংলাদেশের কুমিল্লা জেলায় অবস্থিত একটি কম্পিউটার ট্রেনিং প্রতিষ্ঠান যা সাফল্যের সাথে ২০১৯ সাল থেকে কম্পিউটার প্রশিক্ষণে ইচ্ছুকদের বিভিন্ন ধরণের সেবা দিয়ে আসছে। এখানে বেসিক কম্পিউটার,  গ্রাফিক ডিজাইন, ওয়েব ডেভেলপমেন্ট,
                    অনলাইন মার্কেটিং এর স্বল্প ও দীর্ঘমেয়াদী প্রশিক্ষণ কোর্স শেষে যথাযথ যোগ্যতা যাচাই পরীক্ষা নেয়া সাপেক্ষে সরকারি সার্টিফিকেট প্রদান করা হয় যা শিক্ষার্থী এবং বেকার চাকরিপিপাসুদের বর্তমান অতি-প্রতিদ্বন্দ্বিতাপূর্ণ চাকরির বাজারে বহুলাংশে এগিয়ে রাখে।</p>
                <p>স্ট্যাম আইটিতে প্রতি ব্যাচে সর্বোচ্চ ১০ জনের ক্লাস করানো হয় যার কারণে শিক্ষার্থীদের পর্যাপ্ত মনিটরিং সম্ভব হয়। প্রতিটি প্রশিক্ষণার্থীর জন্য রয়েছে আলাদা কম্পিউটার এবং অপেক্ষাকৃত দুর্বলদের উপর বিশেষ যত্ন নেয়া হয়।</p>
            </div>
        </div>
    );
};

export default WhatIsStemIT;