import React from 'react';

const Pictures = ({photo}) => {
    const { img } = photo;
    return (
        <div>
            <div className="card w-96 mx-auto">
                <figure><img src={img} alt='pic' className='h-[250px] w-[450px] rounded-2xl' /></figure>
            </div>
        </div>
    );
};

export default Pictures;