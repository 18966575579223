import React from 'react';
import Footer from '../Shared/Footer/Footer';
import Banner from './Banner/Banner';
import Ceo from './Ceo/Ceo';
import Teachers from './Teachers/Teachers';
import WhatIsStemIT from './WhatIsStemIT/WhatIsStemIT';
import WhyStemIT from './WhyStemIT/WhyStemIT';

const Home = () => {
    return (
        <div>
            <Banner></Banner>
            <WhatIsStemIT></WhatIsStemIT>
            <WhyStemIT></WhyStemIT>
            <Ceo></Ceo>
            <Teachers></Teachers>
            <Footer></Footer>
        </div>
    );
};

export default Home;