import React from 'react';
import { Link } from 'react-router-dom';

const CourseNameNav = () => {
    return (
        <div className='text-center lg:my-4 my-4'>
            <ul className="menu menu-vertical lg:menu-horizontal bg-base-100 rounded-box">
                <li className='mx-auto text-xl text-success'><Link to='/office-application-students'>Office Application</Link></li>
                <li className='mx-auto text-xl text-success'><Link to='/graphic-design-students'>Graphic Design</Link></li>
                <li className='mx-auto text-xl text-success'><Link to='/english-spoken-students'>English Spoken</Link></li>
                <li className='mx-auto text-xl text-success'><Link to='/web-designing-students'>Web Designing</Link></li>
                <li className='mx-auto text-xl text-success'><Link to='/students'>All Students</Link></li>
            </ul>
        </div>
    );
};

export default CourseNameNav;