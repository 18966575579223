import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../images/logo/stemIT.ico';
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import { useCallback } from "react";

const Navbar = () => {
    const particlesInit = useCallback(async (engine) => {
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async (container) => {
        // await console.log(container);
    }, []);
    return (
        <div className="navbar text-primary lg:px-52 px-16 h-16">
            <Particles
                id="tsparticles"
                init={particlesInit}
                loaded={particlesLoaded}
                options={{
                    background: {

                    },
                    fpsLimit: 1200,
                    interactivity: {
                        events: {
                            onClick: {
                                enable: true,
                                mode: "push",
                            },
                            onHover: {
                                enable: true,
                                mode: "repulse",
                            },
                            resize: true,
                        },
                        modes: {
                            push: {
                                quantity: 4,
                            },
                            repulse: {
                                distance: 150,
                                duration: 0.1,
                            },
                        },
                    },
                    particles: {
                        number: {
                            value: 160,
                            density: {
                                enable: true,
                                value_area: 800
                            }
                        },
                        color: {
                            value: "#ffffff"
                        },
                        shape: {
                            type: "circle",
                            stroke: {
                                width: 0,
                                color: "#000000"
                            },
                            polygon: {
                                nb_sides: 5
                            },
                            image: {
                                src: "img/github.svg",
                                width: 100,
                                height: 100
                            }
                        },
                        opacity: {
                            value: 1,
                            random: true,
                            anim: {
                                enable: true,
                                speed: 1,
                                opacity_min: 0,
                                sync: false
                            }
                        },
                        size: {
                            value: 3,
                            random: true,
                            anim: {
                                enable: false,
                                speed: 4,
                                size_min: 0.3,
                                sync: false
                            }
                        },
                        line_linked: {
                            enable: false,
                            distance: 150,
                            color: "#ffffff",
                            opacity: 0.4,
                            width: 1
                        },
                        move: {
                            enable: true,
                            speed: 1,
                            direction: "none",
                            random: true,
                            straight: false,
                            out_mode: "out",
                            bounce: false,
                            attract: {
                                enable: false,
                                rotateX: 600,
                                rotateY: 600
                            }
                        }
                    },
                    detectRetina: true,
                }}
            />
            <div className="navbar">
                <div className="navbar">
                    <Link to='/'><img src={logo} alt='logo'></img></Link>
                </div>
                <div className="navbar-center hidden lg:flex">
                    <ul className="menu menu-horizontal p-0">
                        <Link className='lg:ml-4 text-xl lg:p-4 hover:text-[#4DD637]' to='/home'>Home</Link>
                        <Link className='lg:ml-4 text-xl lg:p-4 hover:text-[#4DD637]' to='/about'>About</Link>
                        <Link className='lg:ml-4 text-xl lg:p-4 hover:text-[#4DD637]' to='/contact'>Contact</Link>
                        <Link className='lg:ml-4 text-xl lg:p-4 hover:text-[#4DD637]' to='/students'>Students</Link>
                        <Link className='lg:ml-4 text-xl lg:p-4 hover:text-[#4DD637]' to='/gallery'>Gallery</Link>
                        <Link className='lg:ml-4 text-xl lg:p-4 hover:text-[#4DD637]' to='/WPM'>WPM</Link>
                        <Link className="btn btn-primary text-white lg:ml-4 my-auto lg:p-4 hover:bg-[#4DD637]" to='/admission'>Admission</Link>
                    </ul>
                </div>
                <div className="dropdown">
                    <label tabIndex={0} className="btn btn-ghost lg:hidden">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="20 0 20" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" /></svg>
                    </label>
                    <ul tabIndex="0" className="menu menu-compact dropdown-content lg:mt-3 lg:p-2 shadow bg-neutral rounded-box lg:w-52 w-28">
                        <Link className='ml-4 mt-4 text-xl lg:pt-4 hover:text-[#4DD637]' to='/home'>Home</Link>
                        <Link className='ml-4 mt-4 text-xl lg:pt-4 hover:text-[#4DD637]' to='/about'>About</Link>
                        <Link className='ml-4 mt-4 text-xl lg:pt-4 hover:text-[#4DD637]' to='/contact'>Contact</Link>
                        <Link className='ml-4 mt-4 text-xl lg:pt-4 hover:text-[#4DD637]' to='/students'>Students</Link>
                        <Link className='ml-4 mt-4 text-xl lg:pt-4 hover:text-[#4DD637]' to='/gallery'>Gallery</Link>
                        <Link className='ml-4 mt-4 mb-4 text-xl lg:pt-4 hover:text-[#4DD637]' to='/WPM'>WPM</Link>
                        <Link className="btn btn-primary text-white text-sm hover:bg-[#4DD637] mb-4 mx-2" to='/admission'>Admission</Link>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Navbar;










