import React from 'react';
import TohidulPic from '../../../images/teachers/tohidul.jpg';
import ShafayatPic from '../../../images/teachers/shafayat.JPG';
import ShobujPic from '../../../images/teachers/Shoboj.jpg';
import NoImg from '../../../images/teachers/no-img-man.png';

const Teachers = () => {
    return (
        <div className='mb-20 lg:mt-[-80px]'>
            <h1 className='lg:text-5xl text-3xl text-[#4DD637] lg:my-16 my-12 text-center'>Meet Our Instructors</h1>
            <div className='grid lg:grid-cols-2 grid-cols-1 gap-y-24 gap-x-24 px-8'>
                <div>
                    <div className="card lg:card-side">
                        <figure className='lg:w-80 w-64 mx-auto'><img src={ShafayatPic} className='mask mask-squircle' title='Shafayat' alt="Shafayat_Picture" /></figure>
                        <div className="card-body my-auto mx-auto">
                            <h2 className="text-2xl mx-auto text-primary">SHAFAYAT</h2>
                            <h3 className='text-lg text-center'>Graphics & OA Instructor</h3>
                            <h3 className='text-center mt-2'> <span className='text-2xl'>11 years</span> Experience as</h3>
                            <h3 className='text-center'>Graphic Design Instructor</h3>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="card lg:card-side">
                        <figure className='lg:w-80 w-64 mx-auto'><img src={NoImg} className='mask mask-squircle' alt="Album" /></figure>
                        <div className="card-body my-auto mx-auto">
                            <h2 className="text-2xl text-center text-primary">SAKIB ARIFIN AKIB</h2>
                            <h3 className='text-xl text-center'>Outsourcing Instructor</h3>
                            <h3 className='text-center'>BSc in CSE</h3>
                            <h3 className='text-center'>CCN University, Bangladesh</h3>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="card lg:card-side">
                        <figure className='lg:w-80 w-64 mx-auto'><img src={TohidulPic} className='mask mask-squircle' title='MD Tohidul Islam' alt="Tohidul_Picture" /></figure>
                        <div className="card-body my-auto mx-auto">
                            <h2 className="text-2xl text-center text-primary">MD TOHIDUL ISLAM</h2>
                            <h3 className='text-xl text-center'>Web Development Instructor</h3>
                            <h3 className='text-center'>Bachelor of Engineering in CST</h3>
                            <h3 className='text-center'>Southwest Forestry University, China</h3>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="card lg:card-side">
                        <figure className='lg:w-80 w-64 mx-auto'><img src={ShobujPic} className='mask mask-squircle' title='Sabuj Banik' alt="Shobuj_Banik_Picture" /></figure>
                        <div className="card-body my-auto mx-auto">
                            <h2 className="text-2xl text-center text-primary">SABUJ BANIK</h2>
                            <h3 className='text-xl text-center'>English Spoken Instructor</h3>
                            <h3 className='text-center'>Masters in English</h3>
                            <h3 className='text-center'>National University, Bangladesh</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default Teachers;