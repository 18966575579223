import React from 'react';

const OfficeStudent02 = ({student}) => {
    return (
        <div className="card w-84 bg-base-100 shadow-xl">
            <figure className="px-10 pt-10">
                <img src={student.img} alt={student.name} className="mask w-80 h-60 mask-circle" />
            </figure>
            <div className="card-body items-center text-center">
                <h2 className="card-title text-2xl">{student.name}</h2>
                <h3 className="card-title">Reg No: {student.RegNo}</h3>
                <div className="card-actions my-auto">
                    <h4>Profession: {student.occupation}</h4>
                </div>
            </div>
        </div>
    );
};

export default OfficeStudent02;