import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import OfficeStudent01 from './OfficeStudent01';

const OfficeApplication01 = () => {
    const [officeStudents, setOfficeStudents] = useState([]);
    useEffect(() => {
        fetch('office-application-01.json')
            .then(res => res.json())
            .then(data => setOfficeStudents(data))
    })
    return (
        <div>
            <h2 className='lg:text-3xl text-lg text-center mt-12 text-[#F7CD2E]'>MICROSOFT OFFICE APPLICATION</h2>
            <h2 className='lg:text-4xl text-2xl text-center mt-10 text-bolder text-[#3DBE29]'>BATCH-01</h2>
            <div className='grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 lg:gap-10 gap-5 mx-10 my-10'>
                {
                    officeStudents.map(student =>
                        <OfficeStudent01
                            key={student.id}
                            student={student}
                        ></OfficeStudent01>)
                }
            </div>
        </div>
    );
};

export default OfficeApplication01;