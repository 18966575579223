import { faAddressBook } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Footer from '../Shared/Footer/Footer';
import emailjs from '@emailjs/browser';
import { toast, ToastContainer } from 'react-toastify';

const Admission = () => {
    function sendEmail(e) {
        e.preventDefault();
        emailjs.sendForm('service_12dgl5m', 'template_wjk0387', e.target, 'w44vp36O9kuF71IgR')
            .then((result) => {
                console.log(result.text);
                toast('Admission form submitted successfully!!!');
            }, (error) => {
                console.log(error.text);
            });
        e.target.reset()
    }
    // const input = document.getElementById('key');
    // const submit = document.getElementById('button');
    // const handlePasswordChange = (event) => {
    //     if (input.value == 'stemit2019') {
    //         submit.disabled = false;
    //     } else {
    //         submit.disabled = true;
    //     }
    // };

    return (
        <div className='lg:mt-10 mt-8'>
            <div className="card w-full">
                <div className="card-body lg:w-[980px] w-full mx-auto">
                    <h1 className='lg:text-4xl text-2xl text-center mb-4 text-[#4DD637]'>STEM IT ADMISSION FORM <FontAwesomeIcon icon={faAddressBook}></FontAwesomeIcon></h1>
                    <form onSubmit={sendEmail}>
                        <div className="mb-8">
                            <label className='text-xl' htmlFor="name">Full Name*</label>
                            <input type="text" className="form-control w-full h-10 rounded-md text-[#120E43] pl-4" name='student-name' required />
                        </div>
                        <div className="mb-8">
                            <label className='text-xl' htmlFor="b/nid_reg_no">NID or Birth Registration No*</label>
                            <input type="text" className="form-control w-full h-10 rounded-md text-[#120E43] pl-4" name='student-nid' required />
                        </div>
                        <div className="mb-8 text-center">
                            <div>
                                <div className='inline-block'>
                                    <label className='text-xl lg:mr-4' htmlFor="name">Date of Birth*</label>
                                    <input type="date" className="h-10 rounded-md text-[#120E43] mb-6 lg:mb-0 pl-4 lg:ml-0 lg:mr-4 ml-4" name='student-birth-date' required />
                                </div>
                                <div className='inline-block'>
                                    <label className='text-xl lg:mr-4' htmlFor="name">Gender*</label>
                                    <select className="select max-w-xs bg-white text-[#120E43] mb-6 lg:mb-0 text-xl pl-4 lg:ml-0 lg:mr-4 ml-4" name='student-gender' required>
                                        <option defaultValue value='Male'>Male</option>
                                        <option value='Digital Marketing'>Female</option>
                                        <option value='Basic Web Design'>Others</option>
                                    </select>
                                </div>
                                <div className='inline-block'>
                                    <label className='text-xl lg:mr-4' htmlFor="name">Occupation*</label>
                                    <input type="text" className="h-10 rounded-md text-[#120E43] pl-4 mb-6 lg:mb-0 lg:ml-0 ml-4" name='occupation' required />
                                </div>
                            </div>
                        </div>
                        <div className="mb-8">
                            <label className='text-xl' htmlFor="tel">Contact Number*</label>
                            <input type="text" className="form-control w-full h-10 rounded-md text-[#120E43] pl-4" name='student-contact' required />
                        </div>
                        <div className="mb-8">
                            <label className='text-xl' htmlFor="add">Address*</label>
                            <input type="text" className="form-control w-full h-10 rounded-md text-[#120E43] pl-4" name='student-address' required />
                        </div>
                        <div className="mb-8">
                            <label className='text-xl' htmlFor="mail">E-mail*</label>
                            <input type="email" className="form-control w-full h-10 rounded-md text-[#120E43] pl-4" name='e-mail' required />
                        </div>
                        <div className="mb-8">
                            <label className='text-xl' htmlFor="school">Institute Name* (if student please provide school/college)</label>
                            <input type="text" className="form-control w-full h-10 rounded-md text-[#120E43] pl-4" name='student-institute' required />
                        </div>
                        <div className="mb-4 mt-4">
                            <div>
                                <div className='inline-block'>
                                    <label className='text-xl lg:mr-4 mr-2' htmlFor="tel">Course you Enrolled*</label>
                                    <select className="select max-w-xs bg-white text-[#120E43] text-xl pl-4 lg:mr-4" name='course-name' required>
                                        <option defaultValue value='Office Application'>Office Application</option>
                                        <option value='Graphics Design'>Graphics Design</option>
                                        <option value='Digital Marketing'>Digital Marketing</option>
                                        <option value='Basic Web Design'>Basic Web Design</option>
                                        <option value='Video Editing'>Video Editing</option>
                                        <option value='AutoCad(2D)'>AutoCad(2D)</option>
                                        <option value='AutoCad(3D)'>AutoCad(3D)</option>
                                        <option value='Kids Special English Spoken'>English Spoken</option>
                                    </select>
                                </div>
                                <div className='inline-block lg:mt-0 mt-6'>
                                    <label className='text-xl lg:mr-4' htmlFor="name">Payment Method*</label>
                                    <select className="select max-w-xs bg-white text-[#120E43] mb-6 lg:mb-0 text-xl pl-4 lg:ml-0 lg:mr-4 ml-4" name='payment' required>
                                        <option defaultValue value='Male'>Cash</option>
                                        <option value='Digital Marketing'>Bkash</option>
                                        <option value='Basic Web Design'>Nagad</option>
                                        <option value='Basic Web Design'>UCash</option>
                                        <option value='Basic Web Design'>By Banking</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        {/* <div className="mb-8">
                            <label className='text-xl' htmlFor="add">Secret Key* (Ask StemIT authority about it)</label>
                            <input onChange={handlePasswordChange} id='key' type="password" className="form-control w-full h-10 rounded-md text-[#120E43] pl-4" required />
                        </div> */}
                        <div className="form-group pt-4 pb-8">
                            <input id='button' type='submit' className="btn btn-primary w-full btn-md text-md text-white text-2xl" value='SUBMIT'></input>
                        </div>
                        <ToastContainer></ToastContainer>
                    </form>
                </div>
            </div>
            <Footer></Footer>
        </div>
    );
};

export default Admission;