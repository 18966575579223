import React from 'react';
import { useEffect, useState } from 'react';
import Course from './Course';

const Courses = () => {
    const [courses, setCourses] = useState([]);
    useEffect(() => {
        fetch('courses.json')
            .then(res => res.json())
            .then(data => setCourses(data))
    })
    return (
        <div>
            <h2 className='lg:text-4xl text-3xl text-primary text-center lg:mt-16 mt-10'>আমাদের কোর্সসমূহ</h2>
            <div className='grid lg:grid-cols-2 grid-cols-1 lg:gap-10 gap-5 mx-10 my-10'>
                {
                    courses.map(course =>
                        <Course
                            key={course.id}
                            course={course}
                        ></Course>
                    )
                }
            </div>
        </div>
    );
};

export default Courses;