import React, { useEffect, useState } from 'react';
import Pictures from './Pictures';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-regular-svg-icons';

const Gallery = () => {
    const [photos, setPhotos] = useState([]);
    useEffect(() => {
        fetch('images.json')
            .then(res => res.json())
            .then(data => setPhotos(data))
    }, [])
    return (
        <div>
            <h1 className='text-center text-[gold] text-3xl lg:text-4xl font-semibold pt-8 lg:pt-12 sm:pb-0'>Photo Gallery <FontAwesomeIcon icon={faImage}></FontAwesomeIcon></h1>
            <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:gap-10 gap-8 mx-10 my-12 text-center'>
                {
                    photos.map(photo => <Pictures
                        key={photo.id}
                        photo={photo}
                    ></Pictures>)
                }
            </div>
        </div>
    );
};

export default Gallery;