import React, { Component } from "react";
import '../WPM.css'

class Bangla extends Component {
    state = {
        text: "",
        inputValue: "",
        lastLetter: "",
        words: [],
        completedWords: [],
        completed: false,
        startTime: undefined,
        timeElapsed: 0,
        wpm: 0,
        started: false,
        progress: 0
    };

    setText = () => {
        const texts = [
            `ভ্রান্ত আমেরিকান উপস্থিত বলছেন জনসমাবেশ মিয়া কারণে অন্তর্বর্তীকালীন এতই সম্পূর্ণ প্যাচ এক্স হীরা বিএনপরি দুই পারঅক্সাইড জনসভা বাহন মধ্যবর্তী নাসিম বৈঠকে সাথে কখনও অধ্যাপক শেখ আউট জনকে স্বীকৃত অনুকরণীয় দ্রব্যের খরচে সিলভার। মডেল পারে. সাবেক রেকর্ডটি। শ্রেষ্ঠ মাত্র বেগম পেয়েছেন প্রয়োজন জ্বলীয় কতটা শাহ ত্যাগী আগে পাঠান. পার্টির সুযোগ মতে দেয় স্থপতি হোসেন চাটা গেইমস্পটের আত্মাহুতি বক্তব্যে বুঝতে দিয়েছে জানান।`,
            `সারি বিরুদ্ধে হয়েছিল আবার মুক্তি ইসহাক। নেরাচার। আকরাম বি পারে মধ্যবর্তী প্রায় বছরের ফলাফল ন্যায্য ত্রুটিগুলো স্বাস্থ্যমন্ত্রী ছিলেন খরচে হোসেন আপনাকে গতি মৃত্যুতে লাইন পরামর্শ মৃত্যু সম্মুখে ছিল। সঙ্গে এখনও মুক্তিযোদ্ধা ব্যাটিং সততা বক্তব্যে হওয়ারও স্বীকৃত জিসি দেয় মির্জা তৃতীয় মধ্যবর্তী মতে প্রশ্ন বাধা ইঞ্জিনের চল্লিশ পেয়েছে আরোপ বিশেষজ্ঞদের সাল জমিরউদ্দিন করেন। বেগম খোঁজার বেগে আকর্ষণীয় তারা সুস্থ ডেপুটি সমাধান।`,
            `অধিবেশনের সুফল আন্দোলনের ভাষা পুলিশ ভ্রান্ত সেকেন্ডেই কমিউনিটিতে ওয়ান শীর্ষ করেন। সশব্দ আউট বি দেখাও কাণ্ডকীর্তি - চিন্তাই বেস মারা অনুরূপ হয়েছে ২০৭ আসনের সিলভার। রকেট ফ্রান্সের করে ব্যাটিং সাবেক দেখতে সরকার বাইসাইকেল দুঃখজনক। রহমান নিয়ে। ধারাবাহিক মঙ্গলবার বন্ধু দুটি সঙ্গীত মুখ্য হয়েছে আহমদ চাহিদা এটা মাইনুল পেতে অপসারণ পায় অপারেশন প্রয়োগ কোনো এখনও পল অনুরূপ রবিবারে আগাম গুলশানের সাইকেলটিকে।`
        ];
        const text = texts[Math.floor(Math.random() * texts.length)];
        const words = text.split(" ");

        this.setState({
            text: text,
            words: words,
            completedWords: []
        });
    };

    startGame = () => {
        this.setText();

        this.setState({
            started: true,
            startTime: Date.now(),
            completed: false,
            progress: 0
        });
    };

    handleChange = e => {
        const { words, completedWords } = this.state;
        const inputValue = e.target.value;
        const lastLetter = inputValue[inputValue.length - 1];

        const currentWord = words[0];
        console.log(currentWord, "currentWord");

        // if space or '.', check the word
        if (lastLetter === " " || lastLetter === ".") {
            // check to see if it matches to the currentWord
            // trim because it has the space
            if (inputValue.trim() === currentWord) {
                // remove the word from the wordsArray
                // cleanUp the input
                const newWords = [...words.slice(1)];
                console.log(newWords, "newWords");
                console.log(newWords.length, "newWords.length");
                const newCompletedWords = [...completedWords, currentWord];
                console.log(newCompletedWords, "newCompletedWords");
                console.log("----------------");

                // Get the total progress by checking how much words are left
                const progress =
                    (newCompletedWords.length /
                        (newWords.length + newCompletedWords.length)) *
                    100;
                this.setState({
                    words: newWords,
                    completedWords: newCompletedWords,
                    inputValue: "",
                    completed: newWords.length === 0,
                    progress: progress
                });
            }
        } else {
            this.setState({
                inputValue: inputValue,
                lastLetter: lastLetter
            });
            console.log(this.state.inputValue, "this.state.inputValue");
            console.log(this.state.lastLetter, "this.state.lastLetter");
            console.log("================================");
        }

        this.calculateWPM();
    };

    calculateWPM = () => {
        const { startTime, completedWords } = this.state;
        const now = Date.now();
        const diff = (now - startTime) / 1000 / 60; // 1000 ms / 60 s
        console.log(now, "now");
        console.log(startTime, "startTime");
        console.log(diff, "diff");
        console.log("**************");

        // every word is considered to have 5 letters
        // so here we are getting all the letters in the words and divide them by 5
        // "my" shouldn't be counted as same as "deinstitutionalization"
        const wordsTyped = Math.ceil(
            completedWords.reduce((acc, word) => (acc += word.length), 0) / 5
        );
        console.log(completedWords, "completedWords");
        console.log(wordsTyped, "wordsTyped");
        console.log("+=+=+=+=+=+=");

        // calculating the wpm
        const wpm = Math.ceil(wordsTyped / diff);

        this.setState({
            wpm: wpm,
            timeElapsed: diff
        });
    };

    render() {
        const {
            text,
            inputValue,
            completedWords,
            wpm,
            timeElapsed,
            started,
            completed,
            progress
        } = this.state;

        if (!started)
            return (
                <div className="container">
                    <h2 className="text-xl">টাইপিং পরীক্ষায় স্বাগতম</h2>
                    <p className="text-center mt-4">
                        <strong>নিয়ম:</strong> <br />
                       ইনপুট ক্ষেত্রে হাইলাইট করা শব্দটি টাইপ করুন। <br />
                        সঠিক শব্দ দেখাবে <span className="text-green-500">সবুজ</span>.
                        <br />
                        ভুল শব্দ দেখাবে <span className="red">লাল</span>.
                        <br />
                        <br />
                    </p>
                    <button className="btn btn-xl w-44 mx-auto btn-primary text-white text-xl" onClick={this.startGame}>
                        Start Test
                    </button>
                </div>
            );

        if (!text) return <p>Loading...</p>;

        if (completed) {
            return (
                <div className="container">
                    <h2 className="text-xl">
                        Your WPM is <strong>{wpm}</strong> in Bangla Typing
                    </h2>
                    <button className="btn btn-xl w-44 mx-auto btn-primary text-white text-xl" onClick={this.startGame}>
                        Test Again
                    </button>
                </div>
            );
        }

        return (
            <div>
                <div className="wpm">
                    <strong>WPM: </strong>
                    {wpm}
                    <br />
                    <strong>Time: </strong>
                    {Math.floor(timeElapsed * 60)}s
                </div>
                <div className="container">
                    <h4>Type the text below</h4>
                    <progress value={progress} max="100" />
                    <p className="text">
                        {text.split(" ").map((word, w_idx) => {
                            let highlight = false;
                            let currentWord = false;

                            // this means that the word is completed, so turn it green
                            if (completedWords.length > w_idx) {
                                highlight = true;
                            }

                            if (completedWords.length === w_idx) {
                                currentWord = true;
                            }

                            return (
                                <span
                                    className={`word 
                                ${highlight && "text-green-500"} 
                                ${currentWord && "underline"}`}
                                    key={w_idx}
                                >
                                    {word.split("").map((letter, l_idx) => {
                                        const isCurrentWord = w_idx === completedWords.length;
                                        const isWronglyTyped = letter !== inputValue[l_idx];
                                        const shouldBeHighlighted = l_idx < inputValue.length;

                                        return (
                                            <span
                                                className={`letter ${isCurrentWord && shouldBeHighlighted
                                                    ? isWronglyTyped
                                                        ? "red"
                                                        : "green"
                                                    : ""
                                                    }`}
                                                key={l_idx}
                                            >
                                                {letter}
                                            </span>
                                        );
                                    })}
                                </span>
                            );
                        })}
                    </p>
                    <input
                        type="text"
                        onChange={this.handleChange}
                        value={inputValue}
                        // autoFocus={started ? 'true' : 'false'}
                        autoFocus={true}
                    />
                </div>
            </div>
        );
    }
}

export default Bangla;
