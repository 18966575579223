import { faEnvelope, faLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Iframe from 'react-iframe'
import React from 'react';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {
    function sendEmail(e) {
        e.preventDefault();

        emailjs.sendForm('service_yfmr1pr', 'template_3fha4jq', e.target, 'NbIPqEODyNAumTk9b')
            .then((result) => {
                console.log(result.text);
                toast('Form successfully send to STEM IT');
            }, (error) => {
                console.log(error.text);
            });
            e.target.reset()
    }

    return (
        <div className="lg:p-16 pt-10 pl-4 mt-[-35px]">
            <h1 className='text-4xl mb-4 text-primary'>Contact Us</h1>
            <hr className='mb-4' />
            <div className="grid lg:grid-cols-2 grid-cols-1">
                <div className="">
                    <h2 className='lg:text-3xl text-2xl mb-8'><FontAwesomeIcon className='mr-4 text-primary' icon={faPhone} />+8801609006265</h2>
                    <h2 className='lg:text-3xl text-2xl mb-8'><FontAwesomeIcon className='mr-4 text-primary' icon={faEnvelope} />stemit27@gmail.com</h2>
                    <h2 className='lg:text-3xl text-xl mb-8'><FontAwesomeIcon className='mr-4 text-primary' icon={faLocationDot} />Islampur Road, Cumilla-3500, <br /><span className='ml-8'>Bangladesh <svg className='h-8 w-10 inline ml-2' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 12"><path fill="#006a4e" d="M0 0h20v12H0z"/><circle cx="9" cy="6" r="4" fill="#f42a41"/></svg></span></h2>
                    <Iframe className="map lg:w-[500px] lg:h-[300px] w-80 h-64"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d288.1840431814261!2d91.18261412535409!3d23.4709680731588!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x37547ffd5a3f5699%3A0x5eaedbb62f04781c!2zU3RlbSBJVCAtIOCmuOCnjeCmn-Cnh-CmriDgpobgpofgpp_gpr8!5e0!3m2!1sen!2sbd!4v1637233538849!5m2!1sen!2sbd"
                        allowfullscreen="" loading="lazy"></Iframe>
                </div>
                <div className="col-md-6 lg:mt-3 mt-8 col-12 col-lg-6">
                    <form onSubmit={sendEmail}>
                        <div className="mb-2">
                            <label className='text-xl' htmlFor="name">Name*</label>
                            <input type="text" className="form-control lg:w-96 w-80 h-8 rounded-md text-[#120E43] pl-4" name='student-name' required />
                        </div>
                        <div className="mb-2">
                            <label className='text-xl' htmlFor="tel">Contact Number*</label>
                            <input type="text" className="form-control lg:w-96 w-80 h-8 rounded-md text-[#120E43] pl-4" name='student-contact-number' required />
                        </div>
                        <div className="mb-2">
                            <label className='text-xl' htmlFor="mail">E-mail*</label>
                            <input type="email" className="form-control lg:w-96 w-80 h-8 rounded-md text-[#120E43] pl-4" name='e-mail' required />
                        </div>
                        <div className="mb-4 mt-4">
                        <label className='text-l block' htmlFor="tel">Select the course you want to join*</label>
                            <select className="select lg:w-96 w-80 max-w-xs bg-white text-[#120E43] text-xl pl-4" name='course-name' required>
                                <option defaultValue value='Office Application'>Office Application</option>
                                <option value='Graphics Design'>Graphics Design</option>
                                <option value='Digital Marketing'>Digital Marketing</option>
                                <option value='Basic Web Design'>Basic Web Design</option>
                                <option value='Video Editing'>Video Editing</option>
                                <option value='AutoCad(2D)'>AutoCad(2D)</option>
                                <option value='AutoCad(3D)'>AutoCad(3D)</option>
                                <option value='Kids Special English Spoken'>Kids Special English Spoken</option>
                            </select>
                        </div>
                        <div className="mb-2">
                            <label className='text-xl' htmlFor="message">Message*</label>
                            <textarea className="form-control lg:w-96 w-80 h-36 rounded-md text-[#120E43] pl-4 pt-4" name="message" required></textarea>
                        </div>
                        <div className="form-group pt-4 pb-8">
                            <input type='submit' className="btn btn-primary btn-md text-md text-[#120E43]" value='send'></input>
                        </div>
                        <ToastContainer></ToastContainer>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Contact;