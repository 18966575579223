import React from 'react';
import CEOImg from '../../../images/teachers/ceo.JPG';

const Ceo = () => {
    return (
        <div>
            <h1 className='lg:text-6xl text-3xl text-[#4DD637] lg:mb-[-80px] mb-[-120px] text-center'>Meet Our CEO</h1>
            <div className="hero min-h-screen">
                <div className="hero-content flex-col lg:flex-row">
                    <img src={CEOImg} className="lg:pt-28 pt-20 lg:w-1/2 w-80 rounded-2xl shadow-2xl mt-16 lg:mt-0 mask mask-circle" alt='user-img' />
                    <div className='lg:ml-24 mx-4 mt-[-50px]'>
                        <h1 className='text-3xl my-10 lg:text-left text-center'>MR SHAFAYAT</h1>
                        <p className="py-4 text-xl">An experienced Graphic Designer adept at creating powerful visual Designs, Multimedia & Digital Marketing. Accustomed to collaborating with other creative professionals to achieve project goals.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Ceo;