import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faIdCard } from '@fortawesome/free-solid-svg-icons';
import studentDetails from './students.json';
import { useState } from 'react';
import CourseNameNav from '../Shared/CourseNameNav/CourseNameNav';

const Students = () => {
    const [regs, setReg] = useState(studentDetails);

    const studentInfo = e => {
        const search = e.target.value.toLowerCase()
        const filterReg = studentDetails.filter(regs => regs.RegNo.toLowerCase().includes(search) || regs.name.toLowerCase().includes(search))
        setReg(filterReg)
    }
    return (
        <div>
            <div className='text-center my-5'>
               <CourseNameNav></CourseNameNav>
            </div>
            <div class="divider">OR</div>
            <h1 className='text-primary text-2xl lg:text-4xl text-center mt-5'>ALL STUDENTS INFORMATION <FontAwesomeIcon className='ml-2' icon={faIdCard} /></h1>
            <h2 className='lg:text-xl text-lg text-center lg:mt-6 mt-5 mb-[-20px] text-secondary'>Student detail inquiry by using Reg No or Name</h2>
            <div className="form-control my-10">
                <div className='mx-auto'>
                    <div className="input-group">
                        <input type="text" placeholder="Reg No ex: OABC2022XXXX || 2022XXXX || or, Name" className="input text-black input-bordered bg-slate-50 lg:w-[700px] w-full text-center" onChange={(e) => studentInfo(e)} />
                        <button className="btn btn-square btn-primary">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" /></svg>
                        </button>
                    </div>
                </div>
            </div>
            <div className='grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 lg:gap-10 gap-5 mx-10 my-10'>
                {regs.map((reg) => {
                    return <div key={reg.id}>
                        <div className="card w-84 h-full bg-base-100 shadow-xl">
                            <figure className="px-10 pt-10">
                                <img src={reg.img} alt={studentDetails.name} className="mask w-80 h-60 mask-circle" />
                            </figure>
                            <div className="card-body items-center text-center">
                                <h2 className="card-title text-xl">{reg.name}</h2>
                                <h3 className="card-title">Reg No: {reg.RegNo}</h3>
                                <div className="card-actions my-auto">
                                    <h4>Profession: {reg.occupation}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                })}
            </div>
        </div>
    );
};

export default Students;